
	.WordChallenge
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)
		max-width	400px

		p
			font-size 16px
			color #fff

		.letter
			padding 30px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 60px
			height 60px
			margin-bottom 20px

		.choice
			border-radius: 15px
			border 1px solid #fff
			padding 20px
			margin-bottom 20px

