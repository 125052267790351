.teachingVocabulary {
  user-select: none;
  position: absolute;
  top: calc(50% - 80px);
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: calc(100% - 80px);
}
.teachingVocabulary .flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  margin-bottom: 60px;
}
.teachingVocabulary .flex div,
.teachingVocabulary .flex img {
  align-self: center;
}
.teachingVocabulary .word {
  padding: 10px;
  font-size: 3em;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}
.teachingVocabulary .soundOn {
  height: 20px;
  width: 20px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}
.teachingVocabulary .pronounce ::v-deep {
  font-size: 40px;
  margin-right: 20px;
  color: #fff;
  position: relative;
}
.teachingVocabulary .pronounce ::v-deep span {
  background: #fff;
  color: #000;
  padding: 4px 10px;
  font-size: 1.2em;
  display: inline-block;
}
.teachingVocabulary .pronounce ::v-deep:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
}
/*# sourceMappingURL=src/stages/vocabulary/TeachingVocabulary.css.map */