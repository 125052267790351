
	.imageToWords
		user-select none
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)

		.flex
			display: flex
			flex-wrap: wrap
			flex-direction row
			align-items center
			align-content center
			justify-content: center
			-webkit-box-direction: normal
			-webkit-box-pack: center
			margin-bottom 60px

			div, img
				align-self: center

		.word
			padding 10px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 120px
			height 120px
			margin-bottom 20px

